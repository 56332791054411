import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

export default function AppointmentInfoWebPart({ appointment }) {
  const textBold = { sx: { fontWeight: "bold" } };
  return (
    <>
      <Box>
        <Typography variant="h5" sx={{ my: 3 }}>
          Appointment Information
        </Typography>
        <List>
          <ListItem sx={{ py: 0 }}>
            <ListItemText
              primary="Doctor"
              secondary={appointment?.doctorName}
              slotProps={{ primary: textBold }}
            />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText
              primary="Date"
              secondary={appointment?.date}
              slotProps={{ primary: textBold }}
            />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText
              primary="Time"
              secondary={appointment?.time}
              slotProps={{ primary: textBold }}
            />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText
              primary="Symptoms"
              secondary={appointment?.symptoms?.join(", ")}
              slotProps={{ primary: textBold }}
            />
          </ListItem>
        </List>
        <Divider sx={{ my: 2 }} />
      </Box>
    </>
  );
}

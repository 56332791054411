import React from "react";
import { Box, Typography, Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";

const MarketingFooter = () => {
  return (
    <Box component="footer" sx={{ py: 4, bgcolor: "background.paper" }}>
      <Box sx={{ maxWidth: 1200, mx: "auto", px: 2, textAlign: "center" }}>
        <Typography variant="body2" color="text.secondary">
          © {new Date().getFullYear()} MedicAI. All rights reserved.
        </Typography>
        <Box sx={{ mt: 1, display: "flex", justifyContent: "center", gap: 2 }}>
          <MuiLink component={Link} to="/" color="text.secondary">
            Privacy Policy
          </MuiLink>
          <MuiLink component={Link} to="/" color="text.secondary">
            Terms of Service
          </MuiLink>
          <MuiLink component={Link} to="/contact" color="text.secondary">
            Contact Us
          </MuiLink>
        </Box>
      </Box>
    </Box>
  );
};

export default MarketingFooter;

import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Breadcrumbs,
  Link,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useAuth } from "./auth/AuthContext";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import logoicon from "../assets/images/logo192.png";

const CustomAppBar = ({ breadcrumb }) => {
  const { currentUser, logout, userRole } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const breadcrumbs = [
    <Button
      key="0"
      onClick={() =>
        handleNavigation(
          currentUser.role === "doctor"
            ? "/doctor-dashboard"
            : "/patient-dashboard"
        )
      }
    >
      Dashboard
    </Button>,
  ];

  if (breadcrumb)
    breadcrumbs.push(
      <Typography key="3" sx={{ color: "text.primary" }}>
        {breadcrumb}
      </Typography>
    );

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    setDrawerOpen(false);
    navigate(path);
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
    navigate("/login");
  };

  const getInitials = (name) => {
    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}
          >
            <Button component={Link} to="/" color="inherit" sx={{ m: 0, p: 0 }}>
              <img src={logo} style={{ height: "5rem" }} alt="logo" />
            </Button>
          </Typography>
          {currentUser && (
            <>
              {/* Mobile Menu Icon */}
              <IconButton
                edge="start"
                color="inherit"
                sx={{ display: { xs: "block", md: "none" } }}
                onClick={() => setDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>

              {/* Drawer for Mobile Navigation */}
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                PaperProps={{ sx: { width: "50vw" } }}
              >
                <Typography
                  component="div"
                  variant="div"
                  sx={{
                    mx: 3,
                    mt: 3,
                  }}
                >
                  <img
                    src={logoicon}
                    style={{ height: "2rem", width: "2rem" }}
                    alt="logo"
                  />
                </Typography>
                <List>
                  <ListItem
                    button
                    onClick={() =>
                      handleNavigation(
                        userRole === "patient"
                          ? "/patient-dashboard"
                          : "/doctor-dashboard"
                      )
                    }
                  >
                    <ListItemText primary="Dashboard" />
                  </ListItem>
                  {userRole === "patient" && (
                    <ListItem
                      button
                      onClick={() => handleNavigation("/appointment")}
                    >
                      <ListItemText primary="Appointment" />
                    </ListItem>
                  )}
                  {userRole === "doctor" && (
                    <ListItem
                      button
                      onClick={() => handleNavigation("/doctor-admin")}
                    >
                      <ListItemText primary="Availability" />
                    </ListItem>
                  )}
                </List>
              </Drawer>

              {/* Desktop Buttons */}
              <Button
                color="inherit"
                onClick={() =>
                  handleNavigation(
                    userRole === "patient"
                      ? "/patient-dashboard"
                      : "/doctor-dashboard"
                  )
                }
                sx={{ display: { xs: "none", md: "block" } }}
              >
                Dashboard
              </Button>
              {userRole === "patient" && (
                <Button
                  color="inherit"
                  onClick={() => handleNavigation("/appointment")}
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  Appointment
                </Button>
              )}
              {userRole === "doctor" && (
                <Button
                  color="inherit"
                  onClick={() => handleNavigation("/doctor-admin")}
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  Availability
                </Button>
              )}

              {/* Avatar Menu */}
              <IconButton onClick={handleMenuOpen} color="inherit">
                <Avatar sx={{ bgcolor: "primary.main" }}>
                  {getInitials(currentUser.name || currentUser.email)}
                </Avatar>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => handleNavigation("/profile")}>
                  Manage Profile
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>

      {breadcrumbs.length > 1 && (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{
            px: 3,
            pt: 3,
            maxWidth: 800,
            margin: "auto",
          }}
        >
          {breadcrumbs}
        </Breadcrumbs>
      )}
    </>
  );
};

export default CustomAppBar;

import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Card,
  Typography,
  Divider,
  Autocomplete,
  Chip,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useAuth } from "../../auth/AuthContext";
import { doGet, doPost } from "../../../services/apiService";
import LoadingModal from "../../LoadingModal";
import { utilities } from "../../../utilities/utilityService";
import { useNavigate } from "react-router-dom";
import SnackbarWebPart from "../../webparts/snackbar.webpart";
import InactiveProfileWebpart from "../webparts/inactive.profile.webpart";

const Appointment = () => {
  const { currentUser } = useAuth();
  const [bodyLocations, setBodyLocations] = useState([]);
  const [subBodyLocations, setSubBodyLocations] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [selectedBodyLocation, setSelectedBodyLocation] = useState(null);
  const [selectedSubBodyLocation, setSelectedSubBodyLocation] = useState(null);
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: "",
    show: false,
    type: "info",
  });
  const [doctorAvailability, setDoctorAvailability] = useState([]); // Store doctor's availability
  const [availableSlots, setAvailableSlots] = useState([]); // Available slots for the selected doctor
  const [bookedSlots, setBookedSlots] = useState([]); // Booked slots for the selected doctor
  const [showErrorModal, setShowErrorModal] = useState(false); // State for error modal
  const navigate = useNavigate();

  // Fetch body locations on component mount
  useEffect(() => {
    const fetchBodyLocations = async () => {
      setLoading(true);
      try {
        const data = (await doGet("/api/medicai/body/locations")) ?? [];
        setBodyLocations(data);
      } catch (error) {
        setSnackbar({
          message: "Failed to fetch body locations. Please try again.",
          show: true,
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    };
    fetchBodyLocations();
  }, []);

  // Fetch sub-body locations when a body location is selected
  useEffect(() => {
    if (selectedBodyLocation) {
      const fetchSubBodyLocations = async () => {
        setLoading(true);
        try {
          const data =
            (await doPost("/api/medicai/subbody/locations", {
              bodyLocation: selectedBodyLocation.ID,
            })) ?? [];
          setSubBodyLocations(data);
        } catch (error) {
          setSnackbar({
            message: "Failed to fetch sub-body locations. Please try again.",
            show: true,
            type: "error",
          });
        } finally {
          setLoading(false);
        }
      };
      fetchSubBodyLocations();
    }
  }, [selectedBodyLocation]);

  // Fetch symptoms when a sub-body location is selected
  useEffect(() => {
    if (selectedSubBodyLocation) {
      const fetchSymptoms = async () => {
        setLoading(true);
        try {
          const identity = utilities.getIdentity(
            currentUser.gender,
            currentUser.birthYear
          );
          const data =
            (await doPost("api/medicai/symptoms", {
              subBodyLocation: selectedSubBodyLocation.ID,
              identity,
            })) ?? [];
          setSymptoms(data);
        } catch (error) {
          setSnackbar({
            message: "Failed to fetch symptoms. Please try again.",
            show: true,
            type: "error",
          });
        } finally {
          setLoading(false);
        }
      };
      fetchSymptoms();
    }
  }, [selectedSubBodyLocation, currentUser]);

  // Fetch doctors from Firestore
  useEffect(() => {
    const fetchDoctors = async () => {
      setLoading(true);
      try {
        await doGet("/api/doctor/all").then((res) => {
          if (res.succeeded) {
            setDoctors(res.data);
          } else {
            setDoctors([]);
          }
        });
      } catch (error) {
        setSnackbar({
          message: "Failed to fetch doctors. Please try again.",
          show: true,
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    };
    fetchDoctors();
  }, []);

  // Fetch selected doctor's availability and booked appointments
  useEffect(() => {
    if (selectedDoctor) {
      const fetchDoctorData = async () => {
        setLoading(true);
        try {
          await doPost("/api/doctor/availability", {
            id: selectedDoctor.id,
          }).then((res) => {
            if (res.succeeded) {
              setDoctorAvailability(res.data);
            } else {
              throw new Error("Not found.");
            }
          });

          await doPost("/api/doctor/scheduled/appointments", {
            id: selectedDoctor.id,
          }).then((res) => {
            if (res.succeeded) {
              setBookedSlots(res.data);
            } else {
              throw new Error("Not found.");
            }
          });
        } catch (error) {
          setSnackbar({
            message: "Failed to fetch doctor's data. Please try again.",
            show: true,
            type: "error",
          });
        } finally {
          setLoading(false);
        }
      };
      fetchDoctorData();
    }
  }, [selectedDoctor]);

  // Calculate available time slots based on selected date
  useEffect(() => {
    if (appointmentDate && selectedDoctor) {
      const now = new Date();
      const selectedDate = new Date(appointmentDate);

      // Filter availability for the selected date
      const slotsForSelectedDate = doctorAvailability.filter((slot) => {
        const slotStartDate = new Date(slot.startDateTime);
        return (
          slotStartDate.toISOString().split("T")[0] ===
          selectedDate.toISOString().split("T")[0]
        );
      });

      // Generate time slots for each availability block
      const availableTimeSlots = slotsForSelectedDate.flatMap((slot) => {
        const startTime = new Date(slot.startDateTime);
        const endTime = new Date(slot.endDateTime);
        const slots = [];

        let currentTime = startTime;
        while (currentTime < endTime) {
          const slotTime = currentTime.toTimeString().split(" ")[0]; // Get the time string (e.g., "11:00:00")

          // Check if the slot is already booked
          const isBooked = bookedSlots.some(
            (booked) =>
              booked.date === appointmentDate && booked.time === slotTime
          );

          if (!isBooked && currentTime > now) {
            slots.push(slotTime);
          }

          // Increment by 30 minutes
          currentTime = new Date(currentTime.getTime() + 30 * 60 * 1000); // 30 minutes in milliseconds
        }

        return slots;
      });

      setAvailableSlots(availableTimeSlots);

      // Show error modal if no available slots are found
      if (availableTimeSlots.length === 0) {
        setShowErrorModal(true);
      }
    } else {
      setAvailableSlots([]);
    }
  }, [appointmentDate, selectedDoctor, doctorAvailability, bookedSlots]);

  // Handle form submission
  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Check if the selected date and time are within the doctor's availability
      const isSlotAvailable = availableSlots.includes(appointmentTime);
      if (!isSlotAvailable) {
        throw new Error(
          "The selected slot is not available. Please choose another slot."
        );
      }

      // Generate diagnosis report
      const symptomsIds = JSON.stringify(
        selectedSymptoms.map((symptom) => symptom.ID)
      );

      const report =
        (await doPost("/api/medicai/report", {
          model: {
            symptoms: symptomsIds,
            gender: currentUser.gender,
            birthYear: currentUser.birthYear,
          },
        })) ?? [];

      // Save appointment to Firestore
      await doPost("/api/patient/schedule/appointment", {
        model: {
          userId: currentUser.uid,
          userName: currentUser.name,
          doctorId: selectedDoctor.id,
          doctorName: selectedDoctor.name,
          date: appointmentDate,
          time: appointmentTime,
          bodyLocation: selectedBodyLocation?.Name || "",
          subBodyLocation: selectedSubBodyLocation?.Name || "",
          symptoms: selectedSymptoms.map((s) => s.Name),
          createdAt: new Date(),
          report,
        },
      }).then((res) => {
        if (res.succeeded) {
          setSnackbar({
            message: res.message,
            show: true,
            type: "success",
          });
          navigate("/patient-dashboard");
        }
      });
    } catch (error) {
      setSnackbar({
        message:
          error.message || "Failed to schedule appointment. Please try again.",
        show: true,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle closing the error modal
  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
    setSelectedDoctor(null); // Clear selected doctor
    setAppointmentDate(""); // Clear selected date
  };

  if (!currentUser.active) {
    return <InactiveProfileWebpart />;
  }

  return (
    <>
      <Card
        sx={{
          padding: 3,
          maxWidth: 800,
          margin: "auto",
          my: 3,
          border: {
            xs: "none",
          },
          boxShadow: {
            xs: "none",
          },
        }}
      >
        <Typography component="h1" variant="h4" gutterBottom>
          Appointment
        </Typography>
        <Divider sx={{ my: 3 }} />
        {/* Personal Information Section */}
        <Typography variant="h5" sx={{ my: 3 }}>
          Personal Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              label="Name"
              fullWidth
              value={currentUser?.name || ""}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              label="Gender"
              fullWidth
              value={currentUser?.gender || ""}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              label="Birth Year"
              fullWidth
              value={currentUser?.birthYear || ""}
              disabled
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />
        {/* Schedule Appointment Section */}
        <Typography variant="h5" sx={{ mb: 3 }}>
          Schedule Appointment
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              options={doctors}
              getOptionLabel={(option) => option.name}
              value={selectedDoctor}
              onChange={(e, value) => setSelectedDoctor(value)}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  label="Select Doctor"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              label="Date"
              type="date"
              fullWidth
              value={appointmentDate}
              sx={{ textAlign: "left" }}
              onChange={(e) => setAppointmentDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: new Date().toISOString().split("T")[0], // Disable past dates
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="time-select-label">Time</InputLabel>
              <Select
                labelId="time-select-label"
                value={appointmentTime}
                onChange={(e) => setAppointmentTime(e.target.value)}
                disabled={!appointmentDate || availableSlots.length === 0}
              >
                {availableSlots.map((slot) => (
                  <MenuItem key={slot} value={slot}>
                    {slot}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />
        {/* Symptoms Information Section */}
        <Typography variant="h5" sx={{ mb: 3 }}>
          Symptoms Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              options={bodyLocations}
              getOptionLabel={(option) => option.Name}
              value={selectedBodyLocation}
              onChange={(e, value) => setSelectedBodyLocation(value)}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  label="Body Location"
                  fullWidth
                  sx={{ m: 0 }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              options={subBodyLocations}
              getOptionLabel={(option) => option.Name}
              value={selectedSubBodyLocation}
              onChange={(e, value) => setSelectedSubBodyLocation(value)}
              disabled={!selectedBodyLocation}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  label="Sub-Body Location"
                  fullWidth
                  sx={{ m: 0 }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              multiple
              options={symptoms}
              getOptionLabel={(option) => option.Name}
              value={selectedSymptoms}
              onChange={(e, value) => setSelectedSymptoms(value)}
              disabled={!selectedSubBodyLocation}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  label="Symptoms"
                  fullWidth
                  sx={{ m: 0 }}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const tagProps = getTagProps({ index });
                  const { key, ...otherProps } = tagProps;
                  return (
                    <Chip
                      label={option.Name}
                      key={tagProps.key}
                      {...otherProps}
                    />
                  );
                })
              }
            />
          </Grid>
        </Grid>

        {/* Submit Button */}
        <Button
          onClick={handleSubmit}
          variant="contained"
          fullWidth
          sx={{ marginTop: 4 }}
          disabled={
            !selectedSymptoms.length ||
            !selectedDoctor ||
            !appointmentDate ||
            !appointmentTime
          }
        >
          Schedule Appointment
        </Button>
      </Card>

      <LoadingModal open={loading} />
      <SnackbarWebPart
        snacker={snackbar}
        onClose={() => setSnackbar({ ...snackbar, show: false })}
      />

      <Dialog open={showErrorModal} onClose={handleCloseErrorModal}>
        <DialogTitle>No Available Slots</DialogTitle>
        <DialogContent>
          <Typography>
            No available time slots found for the selected doctor and date.
            Please choose another doctor or date.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorModal} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Appointment;

// src/components/marketing/ProductsPage.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Chip,
  Tabs,
  Tab,
  Paper,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Fade,
  Grow,
  Zoom,
} from "@mui/material";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { doGet } from "../../services/apiService";

const ProductsPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [expanded, setExpanded] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch products from Firebase
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        await doGet("/api/products/all").then((res) => {
          if (res.succeeded) {
            setProducts(res.data);
          } else {
            throw new Error("Not found.");
          }
        });

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
        console.error("Error fetching products:", err);
      }
    };

    fetchProducts();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Filter products based on selected tab
  const filteredProducts = () => {
    if (loading || error) return [];

    switch (tabValue) {
      case 1: // Diagnostics tab
        return products.filter((product) => product.category === "diagnostics");
      case 2: // Practice Tools tab
        return products.filter((product) => product.category === "practice");
      case 3: // Analytics tab
        return products.filter((product) => product.category === "analytics");
      default: // All Products tab
        return products;
    }
  };

  const faqs = [
    {
      question: "How accurate is the AI diagnostic tool?",
      answer:
        "Our AI diagnostic tool achieves 92% accuracy compared to board-certified physicians for common conditions, and always recommends consulting with a healthcare professional for final diagnosis.",
    },
    {
      question: "Can I integrate with my existing EHR system?",
      answer:
        "Yes, we support integration with all major EHR systems including Epic, Cerner, and Allscripts through our standardized API.",
    },
    {
      question: "Is patient data secure?",
      answer:
        "We adhere to Protection of Personal Information Act No. 4 of 2013 (“POPI”), and follow strict data protection protocols. All data is stored securely and never shared without consent.",
    },
  ];

  if (error) {
    return (
      <Container sx={{ py: 6, textAlign: "center" }}>
        <Typography color="error" variant="h6">
          Error loading products: {error}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          sx={{ mt: 2 }}
          onClick={() => window.location.reload()}
        >
          Retry
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Typography variant="h2" gutterBottom sx={{ textAlign: "center", mb: 4 }}>
        Our Products
      </Typography>

      <Paper elevation={0} sx={{ mb: 6, borderRadius: 2 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "primary.main",
              height: 3,
            },
            "& .MuiTab-root": {
              textTransform: "none",
              fontWeight: 600,
              minWidth: 120,
              "&.Mui-selected": {
                color: "primary.main",
              },
            },
          }}
        >
          <Tab label="All Products" />
          <Tab label="Diagnostics" />
          <Tab label="Practice Tools" />
          <Tab label="Analytics" />
        </Tabs>
        <Divider />
      </Paper>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 10 }}>
          <CircularProgress size={60} thickness={4} />
        </Box>
      ) : (
        <Fade in={!loading} timeout={500}>
          <Grid container spacing={4} sx={{ mb: 8 }}>
            {filteredProducts().length > 0 ? (
              filteredProducts().map((product, index) => (
                <Grow
                  key={product.id}
                  in={!loading}
                  style={{ transformOrigin: "0 0 0" }}
                  timeout={(index + 1) * 200}
                >
                  <Grid item xs={12} sm={6} md={4}>
                    <Zoom in={!loading} style={{ transitionDelay: "200ms" }}>
                      <Card
                        elevation={3}
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          border: "1px solid",
                          borderColor: "divider",
                          transition: "all 0.3s ease",
                          "&:hover": {
                            boxShadow: 6,
                            transform: "translateY(-4px)",
                          },
                        }}
                      >
                        {/* Card content remains the same */}
                        <CardContent sx={{ flexGrow: 1 }}>
                          <Chip
                            label={product.category.toUpperCase()}
                            size="small"
                            sx={{
                              mb: 2,
                              backgroundColor: "primary.light",
                              color: "primary.contrastText",
                            }}
                          />
                          <Typography gutterBottom variant="h5" component="h3">
                            {product.title}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="text.secondary"
                            paragraph
                          >
                            {product.description}
                          </Typography>
                          <Box
                            component="ul"
                            sx={{
                              pl: 2,
                              "& li": {
                                listStyleType: "none",
                                position: "relative",
                                pl: "1.5em",
                                mb: 1,
                                "&:before": {
                                  content: '"✓"',
                                  position: "absolute",
                                  left: 0,
                                  color: "primary.main",
                                },
                              },
                            }}
                          >
                            {product.features.map((feature, index) => (
                              <li key={index}>{feature}</li>
                            ))}
                          </Box>
                        </CardContent>
                        <CardActions
                          sx={{
                            justifyContent: "space-between",
                            px: 3,
                            pb: 3,
                            pt: 0,
                          }}
                        >
                          <Typography variant="subtitle1" color="primary">
                            {product.price}
                          </Typography>
                          <Button
                            variant="contained"
                            size="medium"
                            component={Link}
                            to={
                              product.cta === "Contact Sales"
                                ? "/contact"
                                : "/signup"
                            }
                          >
                            {product.cta}
                          </Button>
                        </CardActions>
                      </Card>
                    </Zoom>
                  </Grid>
                </Grow>
              ))
            ) : (
              <Grid item xs={12}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    textAlign: "center",
                    border: "1px dashed",
                    borderColor: "divider",
                  }}
                >
                  <Typography variant="h6" color="text.secondary">
                    No products available in this category
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{ mt: 2 }}
                    onClick={() => setTabValue(0)}
                  >
                    View All Products
                  </Button>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Fade>
      )}

      {/* Rest of the component remains unchanged */}
      <Box sx={{ mb: 8 }}>
        <Typography
          variant="h3"
          gutterBottom
          sx={{ textAlign: "center", mb: 4 }}
        >
          How It Works
        </Typography>
        <Grid container spacing={4}>
          {[
            {
              title: "1. Input Symptoms",
              description:
                "Patients describe their symptoms through our intuitive interface.",
            },
            {
              title: "2. AI Analysis",
              description:
                "Our algorithm processes the information against millions of data points.",
            },
            {
              title: "3. Doctor Review",
              description:
                "Healthcare providers validate and refine the AI's findings.",
            },
            {
              title: "4. Treatment Plan",
              description:
                "Patients receive personalized care recommendations.",
            },
          ].map((step, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  height: "100%",
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: 2,
                }}
              >
                <Typography variant="h5" gutterBottom color="primary">
                  {step.title}
                </Typography>
                <Typography variant="body1">{step.description}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h3"
          gutterBottom
          sx={{ textAlign: "center", mb: 4 }}
        >
          Frequently Asked Questions
        </Typography>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleAccordionChange(`panel${index}`)}
            elevation={0}
            sx={{
              mb: 1,
              "&:before": {
                display: "none",
              },
              border: "1px solid",
              borderColor: "divider",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                backgroundColor:
                  expanded === `panel${index}`
                    ? "action.hover"
                    : "background.paper",
                "&:hover": {
                  backgroundColor: "action.hover",
                },
              }}
            >
              <Typography variant="h6" component="h4">
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      <Box
        sx={{
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          p: 6,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Ready to transform healthcare?
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{ mb: 4, maxWidth: 600, mx: "auto" }}
        >
          Join thousands of healthcare providers and patients using our platform
          to deliver better care.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          component={Link}
          to="/signup"
          sx={{
            px: 6,
            "&:hover": {
              backgroundColor: "secondary.dark",
            },
          }}
        >
          Get Started
        </Button>
      </Box>
    </Container>
  );
};

export default ProductsPage;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./components/auth/AuthContext";
import Auth from "./components/auth/Auth";
import PatientDashboard from "./components/medicai/patient/PatientDashboard";
import DoctorDashboard from "./components/medicai/doctor/DoctorDashboard";
import DoctorAdminConsole from "./components/medicai/doctor/DoctorAdminConsole";
import ProfileManagement from "./components/ProfileManagement";
import Appointment from "./components/medicai/patient/Appointment";
import AppointmentDetails from "./components/medicai/AppointmentDetails";
import ProtectedRoute from "./components/ProtectedRoute";
import PasswordReset from "./components/auth/PasswordReset";
import MedicaiMaster from "./components/medicai/MedicaiMaster";

import MarketingMaster from "./components/marketing/MarketingMaster";
import LandingPage from "./components/marketing/LandingPage";
import AboutPage from "./components/marketing/AboutPage";
import ProductsPage from "./components/marketing/ProductsPage";
import ContactPage from "./components/marketing/ContactPage";

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Public Routes */}
          <Route
            path="/"
            element={
              <MarketingMaster>
                <LandingPage />
              </MarketingMaster>
            }
          />
          <Route
            path="/about"
            element={
              <MarketingMaster>
                <AboutPage />
              </MarketingMaster>
            }
          />
          <Route
            path="/products"
            element={
              <MarketingMaster>
                <ProductsPage />
              </MarketingMaster>
            }
          />
          <Route
            path="/products"
            element={
              <MarketingMaster>
                <ProductsPage />
              </MarketingMaster>
            }
          />
          <Route
            path="/contact"
            element={
              <MarketingMaster>
                <ContactPage />
              </MarketingMaster>
            }
          />

          {/* Existing Auth Routes */}
          <Route path="/login" element={<Auth />} />
          <Route path="/signup" element={<Auth />} />
          <Route path="/reset-password" element={<PasswordReset />} />
          {/* Protected Routes */}
          <Route
            path="/patient-dashboard"
            element={
              <ProtectedRoute role="patient">
                <MedicaiMaster component={<PatientDashboard />} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/doctor-dashboard"
            element={
              <ProtectedRoute role="doctor">
                <MedicaiMaster component={<DoctorDashboard />} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <MedicaiMaster
                  component={<ProfileManagement />}
                  breadcrumb="Profile Management"
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/appointment"
            element={
              <ProtectedRoute>
                <MedicaiMaster
                  component={<Appointment />}
                  breadcrumb="New Appointment"
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/doctor-admin"
            element={
              <ProtectedRoute role="doctor">
                <MedicaiMaster
                  component={<DoctorAdminConsole />}
                  breadcrumb="Administration"
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/appointment-details/:id"
            element={
              <ProtectedRoute>
                <MedicaiMaster
                  component={<AppointmentDetails />}
                  breadcrumb="Appointment Details"
                />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Card,
  Divider,
  List,
  ListItem,
  ListItemText,
  Pagination,
  Button,
} from "@mui/material";
import { useAuth } from "../../auth/AuthContext";
import LoadingModal from "../../LoadingModal";
import SnackbarWebPart from "../../webparts/snackbar.webpart";
import { doPost } from "../../../services/apiService";

const DoctorDashboard = () => {
  const { currentUser } = useAuth();
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: "",
    show: false,
    type: "info",
  });
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  // Fetch appointments for the current doctor
  useEffect(() => {
    const fetchAppointments = async () => {
      setLoading(true);
      try {
        await doPost("/api/doctor/scheduled/appointments", {
          id: currentUser.uid,
        }).then((res) => {
          if (res.succeeded) {
            // Filter appointments to only include future ones
            const currentDateTime = new Date();
            const futureAppointments = res.data.filter((appointment) => {
              const appointmentDateTime = new Date(
                `${appointment.date} ${appointment.time}`
              );
              return appointmentDateTime > currentDateTime;
            });

            setAppointments(
              futureAppointments.sort(
                (a, b) =>
                  new Date(`${a.date} ${a.time}`) -
                  new Date(`${b.date} ${b.time}`)
              )
            );
          } else {
            throw new Error("Not found.");
          }
        });
      } catch (error) {
        setSnackbar({
          message: `Error fetching appointments. ${error.message}`,
          show: true,
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    };
    fetchAppointments();
  }, [currentUser]);

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Paginate appointments
  const paginatedAppointments = appointments.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <>
      <Card
        sx={{
          padding: 3,
          maxWidth: 800,
          margin: "auto",
          my: 3,
          border: {
            xs: "none",
          },
          boxShadow: {
            xs: "none",
          },
        }}
      >
        <Typography component="h1" variant="h4" gutterBottom>
          Dashboard
        </Typography>
        <Divider sx={{ my: 3 }} />
        {paginatedAppointments.length > 0 && (
          <Typography variant="h5" sx={{ my: 3 }}>
            Scheduled Appointments
          </Typography>
        )}
        <List>
          {paginatedAppointments.length === 0 && (
            <Typography
              component="div"
              variant="body1"
              sx={{ textAlign: "center" }}
            >
              No Records.
            </Typography>
          )}
          {paginatedAppointments.map((appointment) => (
            <ListItem key={appointment.id} sx={{ py: 0 }}>
              <ListItemText
                primary={`Appointment with ${appointment.userName} on ${appointment.date} at ${appointment.time}`}
                secondary={`Symptoms: ${appointment.symptoms.join(", ")}`}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  navigate(`/appointment-details/${appointment.id}`)
                }
                sx={{ marginRight: 2 }}
              >
                View
              </Button>
            </ListItem>
          ))}
        </List>
        <Pagination
          count={Math.ceil(appointments.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          sx={{ my: 3, display: "flex", justifyContent: "center" }}
        />
      </Card>

      {loading && <LoadingModal open={loading} />}

      <SnackbarWebPart
        snacker={snackbar}
        onClose={() => setSnackbar({ ...snackbar, show: false })}
      />
    </>
  );
};

export default DoctorDashboard;

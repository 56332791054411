import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

export default function PatientInfoWebPart({ patient }) {
  const textBold = { sx: { fontWeight: "bold" } };
  return (
    <>
      <Box>
        <Typography variant="h5" sx={{ my: 3 }}>
          Patient Information
        </Typography>
        <List>
          <ListItem sx={{ py: 0 }}>
            <ListItemText
              primary="Name"
              secondary={patient?.name}
              slotProps={{ primary: textBold }}
            />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText
              primary="Gender"
              secondary={patient?.gender}
              slotProps={{ primary: textBold }}
            />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText
              primary="Date of Birth"
              secondary={patient?.dateOfBirth}
              slotProps={{ primary: textBold }}
            />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText
              primary="Email Address"
              secondary={patient?.email}
              slotProps={{ primary: textBold }}
            />
          </ListItem>
        </List>
        <Divider sx={{ my: 2 }} />
      </Box>
    </>
  );
}

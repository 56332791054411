import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import bg1 from "../../assets/images/bg-1.jpg";
import bg2 from "../../assets/images/bg-2.jpg";
import bg3 from "../../assets/images/bg-3.jpg";
import bg4 from "../../assets/images/bg-4.jpg";
import logo from "../../assets/images/logo192.png";

const AuthMaster = ({ component }) => {
  const getRandomBg = () => {
    const images = [bg1, bg2, bg3, bg4];
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
      }}
    >
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: { xs: "none", md: "flex" }, // Hide on small screens
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: `url('${getRandomBg()}')`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Typography
            component={"h2"}
            variant="h2"
            sx={{
              color: "#FFF",
              textTransform: "uppercase",
              fontWeight: "700",
              mixBlendMode: "difference",
            }}
          >
            Gauweng
          </Typography>
          <Typography
            component={"p"}
            variant="p"
            sx={{
              color: "#FFF",
              textTransform: "uppercase",
              fontWeight: "700",
              mixBlendMode: "difference",
              fontSize: "0.75rem",
              fontFamily: '"Titillium Web",Arial,sans-serif',
            }}
          >
            AI-Powered Healthcare Pre-Screening & Appointment Scheduling
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            "&::before": {
              content: { xs: '""', sm: "none" }, // Apply only on xs screens
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              backgroundImage: `url('${getRandomBg()}')`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              opacity: 0.5,
              zIndex: -1,
            },
          }}
        >
          <Typography
            component="div"
            variant="div"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mb: "3rem",
            }}
          >
            <img
              src={logo}
              style={{ height: "5rem", width: "5rem" }}
              alt="logo"
            />
            <Typography
              component={"h1"}
              variant="h4"
              sx={{
                display: {
                  md: "none",
                },
                textTransform: "uppercase",
                fontWeight: "700",
              }}
            >
              Gauweng
            </Typography>
            <Typography
              component={"h2"}
              variant="h6"
              sx={{
                display: {
                  md: "none",
                },
              }}
            >
              MedicAI
            </Typography>
          </Typography>
          <Typography
            component="div"
            variant="div"
            sx={{ maxWidth: 600, width: "100%" }}
          >
            {component}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AuthMaster;

import React, { useState } from "react";
import { Tab, Tabs, Card } from "@mui/material";
import Login from "./Login";
import Signup from "./Signup";
import AuthMaster from "./AuthMaster";

const Auth = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    event.preventDefault();
  };

  return (
    <AuthMaster
      component={
        <Card sx={{ padding: 3, mx: 3 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            centered
            sx={{ mb: 4 }}
          >
            <Tab label="Login" />
            <Tab label="Sign Up" />
          </Tabs>
          {tabValue === 0 && <Login />}
          {tabValue === 1 && <Signup />}
        </Card>
      }
    />
  );
};

export default Auth;

import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  Divider,
  List,
  ListItem,
  ListItemText,
  Button,
  Pagination,
  Checkbox,
} from "@mui/material";
import { useAuth } from "../../auth/AuthContext";
import LoadingModal from "../../LoadingModal";
import { useNavigate } from "react-router-dom";
import SnackbarWebPart from "../../webparts/snackbar.webpart";
import InactiveProfileWebpart from "../webparts/inactive.profile.webpart";
import { doPost } from "../../../services/apiService";

const PatientDashboard = () => {
  const { currentUser } = useAuth();
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [pastAppointments, setPastAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [upcomingPage, setUpcomingPage] = useState(1);
  const [pastPage, setPastPage] = useState(1);
  const [snackbar, setSnackbar] = useState({
    message: "",
    show: false,
    type: "info",
  });
  const [selectedPastAppointments, setSelectedPastAppointments] = useState([]);
  const itemsPerPage = 5;
  const navigate = useNavigate();

  // Fetch appointments for the current user
  useEffect(() => {
    const fetchAppointments = async () => {
      setLoading(true);
      try {
        await doPost("/api/patient/appointments", {
          uid: currentUser.uid,
        }).then((res) => {
          if (res.succeeded) {
            // Separate into upcoming and past appointments
            const currentDateTime = new Date();
            const upcoming = res.data
              .filter((appointment) => {
                const appointmentDateTime = new Date(
                  `${appointment.date} ${appointment.time}`
                );
                return appointmentDateTime > currentDateTime;
              })
              .sort(
                (a, b) =>
                  new Date(`${a.date} ${a.time}`) -
                  new Date(`${b.date} ${b.time}`)
              );

            const past = res.data
              .filter((appointment) => {
                const appointmentDateTime = new Date(
                  `${appointment.date} ${appointment.time}`
                );
                return appointmentDateTime <= currentDateTime;
              })
              .sort(
                (a, b) =>
                  new Date(`${b.date} ${b.time}`) -
                  new Date(`${a.date} ${a.time}`)
              );

            setUpcomingAppointments(upcoming);
            setPastAppointments(past);
          } else {
            throw new Error("Not found.");
          }
        });
      } catch (error) {
        setSnackbar({
          message: `Error fetching appointments: ${error.message}`,
          show: true,
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    };
    fetchAppointments();
  }, [currentUser]);

  // Cancel an appointment
  const handleCancelAppointment = async (appointmentId) => {
    try {
      await doPost("/api/patient/appointment/delete", {
        id: appointmentId,
      }).then((res) => {
        if (res.succeeded) {
          setUpcomingAppointments((prev) =>
            prev.filter((app) => app.id !== appointmentId)
          );
          setPastAppointments((prev) =>
            prev.filter((app) => app.id !== appointmentId)
          );
          setSnackbar({
            message: "Appointment canceled successfully!",
            show: true,
            type: "success",
          });
        } else {
          throw new Error("Not found.");
        }
      });
    } catch (error) {
      setSnackbar({
        message: `Error canceling appointment: ${error.message}`,
        show: true,
        type: "error",
      });
    }
  };

  // Handle bulk delete for past appointments
  const handleBulkDeletePastAppointments = async () => {
    if (selectedPastAppointments.length === 0) {
      setSnackbar({
        message: "No past appointments selected for deletion.",
        show: true,
        type: "error",
      });
      return;
    }

    setLoading(true);
    try {
      for (const id of selectedPastAppointments) {
        await doPost("/api/patient/appointment/delete", { id });
      }

      setPastAppointments((prev) =>
        prev.filter((app) => !selectedPastAppointments.includes(app.id))
      );
      setSelectedPastAppointments([]);
      setSnackbar({
        message: "Selected past appointments deleted successfully!",
        show: true,
        type: "success",
      });
    } catch (error) {
      setSnackbar({
        message: `Error deleting past appointments: ${error.message}`,
        show: true,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle "Select All" for past appointments
  const handleSelectAllPastAppointments = (event) => {
    if (event.target.checked) {
      const allPastAppointmentIds = paginatedPastAppointments.map(
        (app) => app.id
      );
      setSelectedPastAppointments(allPastAppointmentIds);
    } else {
      setSelectedPastAppointments([]);
    }
  };

  // Handle individual selection for past appointments
  const handleSelectPastAppointment = (id) => {
    if (selectedPastAppointments.includes(id)) {
      setSelectedPastAppointments(
        selectedPastAppointments.filter((item) => item !== id)
      );
    } else {
      setSelectedPastAppointments([...selectedPastAppointments, id]);
    }
  };

  // Paginate upcoming appointments
  const paginatedUpcomingAppointments = upcomingAppointments.slice(
    (upcomingPage - 1) * itemsPerPage,
    upcomingPage * itemsPerPage
  );

  // Paginate past appointments
  const paginatedPastAppointments = pastAppointments.slice(
    (pastPage - 1) * itemsPerPage,
    pastPage * itemsPerPage
  );

  if (!currentUser.active) {
    return <InactiveProfileWebpart />;
  }

  return (
    <>
      <Card
        sx={{
          padding: 3,
          maxWidth: 800,
          margin: "auto",
          my: 3,
          border: {
            xs: "none",
          },
          boxShadow: {
            xs: "none",
          },
        }}
      >
        <Typography component="h1" variant="h4" gutterBottom>
          Dashboard
        </Typography>
        <Divider sx={{ my: 3 }} />

        {/* Upcoming Appointments */}
        {paginatedUpcomingAppointments.length > 0 && (
          <>
            <Typography variant="h5" sx={{ my: 3 }}>
              Upcoming Appointments
            </Typography>
            <List>
              {paginatedUpcomingAppointments.map((appointment) => (
                <ListItem key={appointment.id} sx={{ py: 0 }}>
                  <ListItemText
                    primary={`Appointment with ${appointment.doctorName} on ${appointment.date} at ${appointment.time}`}
                    secondary={`Symptoms: ${appointment.symptoms.join(", ")}`}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      navigate(`/appointment-details/${appointment.id}`)
                    }
                    sx={{ marginRight: 2 }}
                  >
                    View
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleCancelAppointment(appointment.id)}
                  >
                    Cancel
                  </Button>
                </ListItem>
              ))}
            </List>
            <Pagination
              count={Math.ceil(upcomingAppointments.length / itemsPerPage)}
              page={upcomingPage}
              onChange={(event, value) => setUpcomingPage(value)}
              sx={{ my: 3, display: "flex", justifyContent: "center" }}
            />
            <Divider sx={{ my: 3 }} />
          </>
        )}

        {/* Past Appointments */}
        {paginatedPastAppointments.length > 0 && (
          <>
            <Typography variant="h5" sx={{ my: 3 }}>
              Past Appointments
            </Typography>
            <Button
              onClick={handleBulkDeletePastAppointments}
              variant="contained"
              color="error"
              disabled={selectedPastAppointments.length === 0}
              sx={{ mb: 2 }}
            >
              Delete Selected
            </Button>
            <List>
              <ListItem
                key={0}
                sx={{ p: 0, display: "flex", justifyContent: "space-between" }}
              >
                <Checkbox
                  checked={
                    selectedPastAppointments.length ===
                    paginatedPastAppointments.length
                  }
                  onChange={handleSelectAllPastAppointments}
                />
                <ListItemText primary={`Select All`} />
              </ListItem>
              {paginatedPastAppointments.map((appointment) => (
                <ListItem
                  key={appointment.id}
                  sx={{
                    p: 0,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Checkbox
                    checked={selectedPastAppointments.includes(appointment.id)}
                    onChange={() => handleSelectPastAppointment(appointment.id)}
                  />
                  <ListItemText
                    primary={`Appointment with ${appointment.doctorName} on ${appointment.date} at ${appointment.time}`}
                    secondary={`Symptoms: ${appointment.symptoms.join(", ")}`}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      navigate(`/appointment-details/${appointment.id}`)
                    }
                    sx={{ marginRight: 2 }}
                  >
                    View
                  </Button>
                </ListItem>
              ))}
            </List>
            <Pagination
              count={Math.ceil(pastAppointments.length / itemsPerPage)}
              page={pastPage}
              onChange={(event, value) => setPastPage(value)}
              sx={{ my: 3, display: "flex", justifyContent: "center" }}
            />
            <Divider sx={{ my: 3 }} />
          </>
        )}

        {/* No Appointments Message */}
        {paginatedUpcomingAppointments.length === 0 &&
          paginatedPastAppointments.length === 0 && (
            <Typography
              component="div"
              variant="body1"
              sx={{ textAlign: "center" }}
            >
              <p>No Records.</p>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(`/appointment`)}
                sx={{ marginRight: 2 }}
              >
                Schedule New Appointment
              </Button>
            </Typography>
          )}
      </Card>

      {loading && <LoadingModal open={loading} />}

      <SnackbarWebPart
        snacker={snackbar}
        onClose={() => setSnackbar({ ...snackbar, show: false })}
      />
    </>
  );
};

export default PatientDashboard;

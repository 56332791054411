import React, { createContext, useContext, useEffect, useState } from "react";
import { doGet, doPost } from "../../services/apiService";
import SnackbarWebPart from "../webparts/snackbar.webpart";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [snackbar, setSnackbar] = useState({
    message: "",
    show: false,
    type: "info",
  });

  // Logout function
  const logout = async () => {
    try {
      setLoading(true);
      await doPost("/api/user/signout").then((res) => {
        if (res.succeeded) {
          setCurrentUser(null);
          setUserRole(null);
          setSnackbar({
            message: res.message,
            show: true,
            type: "success",
          });
        }
      });
    } catch (error) {
      setSnackbar({
        message: `Error signing user out:", ${error.message}`,
        show: true,
        type: "success",
      });
    } finally {
      setLoading(false);
    }
  };

  // Auto-logout after 10 minutes of inactivity
  useEffect(() => {
    const inactivityTimer = setInterval(() => {
      if (Date.now() - lastActivity > 10 * 60 * 1000) {
        if (currentUser) logout();
      }
    }, 1000);
    return () => clearInterval(inactivityTimer);
  }, [lastActivity, currentUser]);

  // Track user activity
  const updateActivity = () => {
    setLastActivity(Date.now());
  };

  // Update user data
  const updateUserData = (user) => {
    setCurrentUser({ ...currentUser, ...user });
  };

  // Manually set user and role after login
  const setUserAndRole = (user) => {
    setCurrentUser(user);
    setUserRole(user.role);
  };

  // Keep onAuthStateChanged for session persistence (e.g., page reload)
  useEffect(() => {
    const unsubscribe = async () => {
      setLoading(true);
      await doGet("/api/user/state/observer")
        .then(async (res) => {
          if (res.succeeded && res.data) {
            const user = res.data;
            await doPost("/api/user", { uid: user.uid }).then((res) => {
              if (res.succeeded) {
                setUserAndRole(res.data.user);
              } else {
                setCurrentUser(null);
                setUserRole(null);
              }
            });
          } else {
            setCurrentUser(null);
            setUserRole(null);
          }
          setLoading(false);
        })
        .finally(() => setLoading(false));
    };

    unsubscribe();
  }, []);

  return (
    <>
      <AuthContext.Provider
        value={{
          currentUser,
          userRole,
          logout,
          updateActivity,
          updateUserData,
          setUserAndRole,
        }}
      >
        {!loading && children}
      </AuthContext.Provider>
      <SnackbarWebPart
        snacker={snackbar}
        onClose={() => setSnackbar({ ...snackbar, show: false })}
      />
    </>
  );
};

export const useAuth = () => useContext(AuthContext);

import React, { useState } from "react";
import { TextField, Button, Card, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingModal from "../LoadingModal";
import SnackbarWebPart from "../webparts/snackbar.webpart";
import PublicMaster from "./AuthMaster";
import { doPost } from "../../services/apiService";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: "",
    show: false,
    type: "info",
  });

  const navigate = useNavigate();

  const validateForm = () => {
    if (!email) {
      setSnackbar({
        message: "Email is required.",
        show: true,
        type: "error",
      });
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setSnackbar({
        message: "Invalid email address.",
        show: true,
        type: "error",
      });
      return false;
    }
    return true;
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setSnackbar({ message: "", show: false, type: "info" }); // Reset Snackbar
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    try {
      await doPost("/api/user/reset/password", { email }).then((res) => {
        if (res.succeeded) {
          setSnackbar({
            message: res.message,
            show: true,
            type: "success",
          });

          // Redirect to login after 5 seconds
          setTimeout(() => {
            navigate("/login");
          }, 6000);
        }
      });
    } catch (error) {
      setSnackbar({
        message: `Error sending password reset email: ${error.message}`,
        show: true,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PublicMaster
      component={
        <Card sx={{ padding: 3, mx: 3 }}>
          <Typography variant="h5" align="center" gutterBottom>
            Reset Password
          </Typography>

          <form onSubmit={handleResetPassword}>
            <TextField
              size="small"
              label="Email"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ marginTop: 2 }}
            >
              Send Reset Link
            </Button>
          </form>

          {/* Link to go back to Login */}
          <Typography variant="body2" align="center" sx={{ marginTop: 2 }}>
            Remember your password?{" "}
            <Link href="/login" underline="hover">
              Login
            </Link>
          </Typography>

          <LoadingModal open={loading} />

          <SnackbarWebPart
            snacker={snackbar}
            onClose={() => setSnackbar({ ...snackbar, show: false })}
          />
        </Card>
      }
    />
  );
};

export default PasswordReset;

import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Card,
  Typography,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useAuth } from "./auth/AuthContext";
import { doPost } from "../services/apiService";
import LoadingModal from "./LoadingModal";
import { useNavigate } from "react-router-dom";
import SnackbarWebPart from "./webparts/snackbar.webpart";

const ProfileManagement = () => {
  const { currentUser, updateUserData } = useAuth();
  const [name, setName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [bmi, setBmi] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: "",
    show: false,
    type: "info",
  });

  const navigate = useNavigate();

  // Fetch user data on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        setLoading(true);
        await doPost("/api/user", { uid: currentUser.uid })
          .then((res) => {
            if (res.succeeded) {
              const user = res.data.user;
              setName(user.name || "");
              setDateOfBirth(user.dateOfBirth || "");
              setGender(user.gender || "");
              setHeight(user.height || "");
              setWeight(user.weight || "");
              setBmi(user.BMI || "");
            }
          })
          .finally(() => setLoading(false));
      }
    };
    fetchUserData();
  }, [currentUser]);

  // Calculate BMI
  const calculateBmi = (height, weight) => {
    if (height > 0 && weight > 0) {
      const heightInMeters = height / 100;
      const bmiValue = (weight / (heightInMeters * heightInMeters)).toFixed(2);
      setBmi(bmiValue);
    } else {
      setBmi("");
    }
  };

  // Handle height or weight change
  useEffect(() => {
    calculateBmi(height, weight);
  }, [height, weight]);

  // Handle form submission
  const handleSave = async () => {
    setSnackbar({ message: "", show: false, type: "info" });
    setLoading(true);

    if (!name || !dateOfBirth || !gender || !height || !weight) {
      setSnackbar({
        message: "All fields are required.",
        show: true,
        type: "error",
      });
      setLoading(false);
      return;
    }

    try {
      const user = {
        uid: currentUser.uid,
        name: name,
        dateOfBirth: dateOfBirth,
        gender: gender,
        height: height,
        weight: weight,
        BMI: bmi,
        active: true,
      };

      await doPost("/api/user/update", { model: user }).then((update) => {
        if (update.succeeded) {
          updateUserData(user);
          setSnackbar({
            message: update.message,
            show: true,
            type: "success",
          });

          setTimeout(() => {
            navigate(`/${currentUser.role}-dashboard`);
          }, 6000);
        }
      });
    } catch (error) {
      setSnackbar({
        message: "Error updating profile: " + error.message,
        show: true,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card
        sx={{
          padding: 3,
          maxWidth: 800,
          margin: "auto",
          my: 3,
          border: {
            xs: "none",
          },
          boxShadow: {
            xs: "none",
          },
        }}
      >
        <Typography component="h1" variant="h4" gutterBottom>
          Profile Management
        </Typography>
        <Divider sx={{ my: 3 }} />
        <Typography variant="h5" sx={{ my: 3 }}>
          Personal Information
        </Typography>

        <TextField
          size="small"
          label="Name"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          size="small"
          label="Date of Birth"
          type="date"
          fullWidth
          margin="normal"
          value={dateOfBirth}
          onChange={(e) => setDateOfBirth(e.target.value)}
          InputLabelProps={{ shrink: true }}
          required
        />
        <FormControl component="fieldset">
          <FormLabel component="legend">Gender</FormLabel>
          <RadioGroup
            row
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            required
          >
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel
              value="female"
              control={<Radio />}
              label="Female"
            />
          </RadioGroup>
        </FormControl>
        <TextField
          size="small"
          label="Height (cm)"
          fullWidth
          margin="normal"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
          required
        />
        <TextField
          size="small"
          label="Weight (kg)"
          fullWidth
          margin="normal"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
          required
        />
        <TextField
          size="small"
          label="BMI"
          fullWidth
          margin="normal"
          value={bmi}
          disabled
        />
        <Button
          onClick={handleSave}
          variant="contained"
          fullWidth
          sx={{ marginTop: 2 }}
        >
          Save
        </Button>
      </Card>

      <LoadingModal open={loading} />

      <SnackbarWebPart
        snacker={snackbar}
        onClose={() => setSnackbar({ ...snackbar, show: false })}
      />
    </>
  );
};

export default ProfileManagement;

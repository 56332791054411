import axios from "axios";

const doGet = async (url) =>
  await axios.get(url).then((response) => response.data);

const doPost = async (url, payload) =>
  await axios
    .post(url, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data);

export { doGet, doPost };

import React, { useState } from "react";
import { Typography, TextField, Button, Container, Grid } from "@mui/material";
import { doPost } from "../../services/apiService";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await doPost("/api/user/callback", {
        model: {
          ...formData,
          timestamp: new Date(),
        },
      }).then((res) => {
        if (res.succeeded) {
          setSubmitted(true);
        } else {
          throw new Error("Please try again");
        }
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  if (submitted) {
    return (
      <Container sx={{ py: 8, textAlign: "center" }}>
        <Typography variant="h4" gutterBottom>
          Thank You!
        </Typography>
        <Typography>
          We've received your message and will get back to you soon.
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ py: 8 }}>
      <Typography variant="h3" gutterBottom sx={{ mb: 4 }}>
        Contact Us
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              margin="normal"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              required
            />
            <TextField
              fullWidth
              label="Email"
              type="email"
              variant="outlined"
              margin="normal"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              required
            />
            <TextField
              fullWidth
              label="Message"
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
              value={formData.message}
              onChange={(e) =>
                setFormData({ ...formData, message: e.target.value })
              }
              required
            />
            <Button type="submit" variant="contained" sx={{ mt: 2 }}>
              Submit
            </Button>
          </form>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Our Office
          </Typography>
          <Typography>1442 Crocosmia Street</Typography>
          <Typography>Centurion, Irene 0157</Typography>
          <Typography>Email: info@medicai.co.za</Typography>
          <Typography>Phone: (079) 750-8901</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactPage;

// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light", // or 'dark'
    primary: {
      main: "#000000", // Black
    },
    secondary: {
      main: "#808080", // Grey
    },
    background: {
      default: "#FFFFFF", // White
      paper: "#FFFFFF", // White
    },
    text: {
      primary: "#000000", // Black
      secondary: "#808080", // Grey
    },
  },
  typography: {
    fontFamily: '"Titillium Web", Arial, sans-serif', // Default font for all text
    h1: {
      fontWeight: 600, // SemiBold for h1
    },
    h2: {
      fontWeight: 600, // SemiBold for h2
    },
    h3: {
      fontWeight: 600, // SemiBold for h3
    },
    h4: {
      fontWeight: 600, // SemiBold for h4
    },
    h5: {
      fontWeight: 600, // SemiBold for h5
    },
    h6: {
      fontWeight: 600, // SemiBold for h6
    },
    body1: {
      fontWeight: 200, // ExtraLight for normal text
    },
    body2: {
      fontWeight: 200, // ExtraLight for secondary text
    },
    button: {
      fontWeight: 600, // SemiBold for buttons
      textTransform: "none", // Optional: to keep button text in normal case
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Optional: Custom border radius for buttons
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#000000", // Black
          textDecoration: "underline", // Underline by default
          "&:hover": {
            textDecoration: "none", // No underline on hover
          },
        },
      },
    },
  },
});

export default theme;

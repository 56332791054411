import React from "react";
import { Box, Container, CssBaseline } from "@mui/material";
import MarketingHeader from "./MarketingHeader";
import MarketingFooter from "./MarketingFooter";

const MarketingMaster = ({ children }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <CssBaseline />
      <MarketingHeader />
      <Container component="main" sx={{ my: 4, flex: 1 }}>
        {children}
      </Container>
      <MarketingFooter />
    </Box>
  );
};

export default MarketingMaster;

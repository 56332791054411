import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Typography, Button } from "@mui/material";
import CustomAppBar from "../../AppBar";

export default function InactiveProfileWebpart() {
  const navigate = useNavigate();
  return (
    <>
      <CustomAppBar />
      <Card
        sx={{
          padding: 3,
          maxWidth: 800,
          margin: "auto",
          my: 3,
          border: {
            xs: "none",
          },
          boxShadow: {
            xs: "none",
          },
        }}
      >
        <Typography component="h1" variant="h4" gutterBottom>
          Activate Profile
        </Typography>
        <Typography component="div" variant="body1" sx={{ my: 5 }}>
          Please complete your profile to unlock additional features and enhance
          the experience. This process takes only a minute.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(`/profile`)}
          sx={{ marginRight: 2 }}
        >
          Manage Profile
        </Button>
      </Card>
    </>
  );
}

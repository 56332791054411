import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Typography, Card, Box, Divider, Grid } from "@mui/material";
import LoadingModal from "../LoadingModal";
import { doPost } from "../../services/apiService";
import DiagnosisInfoWebPart from "./webparts/diagnosis.info.webpart";
import PatientInfoWebpart from "./webparts/patient.info.webpart";
import AppointmentInfoWebPart from "./webparts/appointment.info.webpart";
import SnackbarWebPart from "../webparts/snackbar.webpart";

const AppointmentDetails = () => {
  const [loading, setLoading] = useState(false);
  const [appointment, setAppointment] = useState({});
  const { id } = useParams();
  const [snackbar, setSnackbar] = useState({
    message: "",
    show: false,
    type: "info",
  });

  // Fetch appointment for the current user
  useEffect(() => {
    const fetchAppointment = async (id) => {
      setLoading(true);
      try {
        await doPost("/api/patient/appointment", {
          id,
        }).then(async (appointment) => {
          if (appointment.succeeded && appointment.data.userId) {
            await doPost("/api/user", {
              uid: appointment.data.userId,
            }).then((res) => {
              if (res.succeeded) {
                const patient = res.data.user;
                setAppointment({ ...appointment.data, patient });
              } else {
                setSnackbar({
                  message: `Patient not found.`,
                  show: true,
                  type: "error",
                });
              }
            });
          } else {
            throw new Error("Not Found");
          }
        });
      } catch (error) {
        setSnackbar({
          message: `Error fetching appointment details. ${error.message}`,
          show: true,
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchAppointment(id);
  }, [id]);

  return (
    <>
      {loading && <LoadingModal open={loading} />}
      <Card
        sx={{
          padding: 3,
          maxWidth: 800,
          margin: "auto",
          my: 3,
          border: {
            xs: "none",
          },
          boxShadow: {
            xs: "none",
          },
        }}
      >
        <Typography component="h1" variant="h4" gutterBottom>
          Appointment Details
        </Typography>
        <Divider sx={{ my: 3 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <PatientInfoWebpart patient={appointment?.patient} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppointmentInfoWebPart appointment={appointment} />
          </Grid>
        </Grid>

        {/* Patient Information */}

        {/* Appointment Information */}

        {/* Diagnosis Report */}
        {appointment?.report?.diagnosis.length > 0 && (
          <>
            <Typography variant="h5" sx={{ my: 3 }}>
              Pre-Screening Report
            </Typography>
            {appointment?.report?.diagnosis.map((diagnosis, key) => (
              <DiagnosisInfoWebPart key={key} diagnosis={diagnosis} />
            ))}
            <Divider sx={{ mt: 2 }} />
          </>
        )}

        {/* Diagnosis Remarks */}
        {appointment?.report?.severeness.length > 0 && (
          <>
            <Typography variant="h5" sx={{ my: 3 }}>
              Remarks
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1" sx={{ my: 3 }}>
                <Typography variant="p">
                  {appointment?.report?.severeness.map(
                    (remark, i) => `${i + 1}.${remark}`
                  )}
                </Typography>
              </Typography>
            </Box>
          </>
        )}

        <SnackbarWebPart
          snacker={snackbar}
          onClose={() => setSnackbar({ ...snackbar, show: false })}
        />
      </Card>
    </>
  );
};

export default AppointmentDetails;

import React, { useEffect, useRef, useState } from "react";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import "./styles/diagnosis.info.webpart.css";

export default function DiagnosisInfoWebPart({ diagnosis }) {
  const itemRef = useRef(null);
  const [fontSize, setFontSize] = useState("16px");
  const textBold = { sx: { fontWeight: "bold" } };

  useEffect(() => {
    if (itemRef.current) {
      const height = itemRef.current.clientHeight;
      setFontSize(`${height * 0.85}px`);
    }
  }, []);
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <List className="item" ref={itemRef}>
          <ListItem sx={{ py: 0 }}>
            <Typography variant="h5" sx={{ my: 2 }}>
              Probable Issue #{diagnosis.Issue.Ranking}
            </Typography>
            <span className="watermark" style={{ fontSize: fontSize }}>
              #{diagnosis.Issue.Ranking}
            </span>
          </ListItem>

          <ListItem sx={{ py: 0, wordWrap: "break-word" }}>
            <ListItemText
              primary="Accuracy"
              secondary={`${diagnosis.Issue.Accuracy}%`}
              slotProps={{ primary: textBold }}
            />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText
              primary="Name"
              secondary={diagnosis.Issue.Name}
              slotProps={{ primary: textBold }}
            />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText
              primary="Professional Name"
              secondary={diagnosis.Issue.ProfName}
            />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText
              primary="ICD"
              secondary={diagnosis.Issue.Icd}
              slotProps={{ primary: textBold }}
            />
          </ListItem>
          <ListItem sx={{ py: 0, wordWrap: "break-word" }}>
            <ListItemText
              primary="ICD Name"
              secondary={diagnosis.Issue.IcdName}
              slotProps={{ primary: textBold }}
            />
          </ListItem>

          <ListItem sx={{ py: 0 }}>
            <Typography variant="h5" sx={{ my: 2 }}>
              Specialisation
            </Typography>
          </ListItem>
          {diagnosis.Specialisation.map((speciality, key) => (
            <ListItem sx={{ py: 0 }} key={key}>
              <ListItemText
                primary=""
                secondary={speciality.Name}
                slotProps={{ primary: textBold }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
}

import React from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import logo from "../../assets/images/logo.svg";

const MarketingHeader = () => {
  const { currentUser } = useAuth();

  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <Button component={Link} to="/" color="inherit" sx={{ m: 0, p: 0 }}>
            <img src={logo} style={{ height: "5rem" }} alt="logo" />
          </Button>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button component={Link} to="/about" color="inherit">
            About
          </Button>
          <Button component={Link} to="/products" color="inherit">
            Products
          </Button>
          <Button component={Link} to="/contact" color="inherit">
            Contact
          </Button>
          {currentUser ? (
            <Button
              component={Link}
              to={
                currentUser.role === "doctor"
                  ? "/doctor-dashboard"
                  : "/patient-dashboard"
              }
              color="inherit"
            >
              Dashboard
            </Button>
          ) : (
            <Button
              component={Link}
              to="/login"
              target="_blank"
              color="inherit"
            >
              Login
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default MarketingHeader;

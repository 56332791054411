import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  InputLabel,
  InputAdornment,
  FormControl,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import LoadingModal from "../LoadingModal";
import SnackbarWebPart from "../webparts/snackbar.webpart";
import { doPost } from "../../services/apiService";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: "",
    show: false,
    type: "info",
  });

  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const handleMouseUpPassword = (event) => event.preventDefault();

  const validateForm = () => {
    if (!name || !email || !password) {
      setSnackbar({
        message: "All fields are required.",
        show: true,
        type: "error",
      });
      return false;
    }
    if (password.length < 6) {
      setSnackbar({
        message: "Password must be at least 6 characters long.",
        show: true,
        type: "error",
      });
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setSnackbar({
        message: "Invalid email address.",
        show: true,
        type: "error",
      });
      return false;
    }
    return true;
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setSnackbar({ message: "", show: false, type: "info" });
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    try {
      await doPost("/api/user/signup", {
        email,
        password,
        name,
      }).then((res) => {
        if (res.succeeded) {
          setSnackbar({
            message: `${res.message}. Redirecting to login.`,
            show: true,
            type: "success",
          });

          setTimeout(() => {
            navigate("/");
          }, 6000);
        } else {
          throw new Error(res.data.error);
        }
      });
    } catch (error) {
      setSnackbar({
        message: `"Error signing up:", ${error.message}`,
        show: true,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography variant="h5" align="center" gutterBottom>
        Sign Up
      </Typography>

      <form onSubmit={handleSignup}>
        <TextField
          size="small"
          label="Name"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          size="small"
          label="Email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <FormControl
          sx={{ my: 1, width: "100%" }}
          variant="outlined"
          size="small"
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={
                    showPassword ? "hide the password" : "display the password"
                  }
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  onMouseUp={handleMouseUpPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ marginTop: 2 }}
        >
          Sign Up
        </Button>
      </form>

      <LoadingModal open={loading} />

      <SnackbarWebPart
        snacker={snackbar}
        onClose={() => setSnackbar({ ...snackbar, show: false })}
      />
    </>
  );
};

export default Signup;

import CustomAppBar from "../AppBar";

export default function MedicaiMaster({ breadcrumb, component }) {
  return (
    <>
      <CustomAppBar breadcrumb={breadcrumb || null} />
      {component}
    </>
  );
}

import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  TextField,
  Divider,
  Button,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
  IconButton,
  Pagination,
} from "@mui/material";
import { useAuth } from "../../auth/AuthContext";
import LoadingModal from "../../LoadingModal";
import SnackbarWebPart from "../../webparts/snackbar.webpart";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { doPost } from "../../../services/apiService";

const DoctorAdminConsole = () => {
  const { currentUser } = useAuth();
  const [availability, setAvailability] = useState([]); // For upcoming availability
  const [pastAvailability, setPastAvailability] = useState([]); // For past availability
  const [newAvailability, setNewAvailability] = useState({
    startDateTime: "",
    endDateTime: "",
  });
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bookEntireDay, setBookEntireDay] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: "",
    show: false,
    type: "info",
  });
  const [editingAvailabilityId, setEditingAvailabilityId] = useState(null);
  const [availabilityPage, setAvailabilityPage] = useState(1);
  const [pastAvailabilityPage, setPastAvailabilityPage] = useState(1);
  const [appointmentsPage, setAppointmentsPage] = useState(1);
  const [selectedPastAvailabilities, setSelectedPastAvailabilities] = useState(
    []
  );
  const itemsPerPage = 5;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const availability = await doPost("/api/doctor/availability", {
          id: currentUser.uid,
        });

        // Separate into future and past availability
        const currentDateTime = new Date();
        const futureAvailability = availability.data
          .filter((avail) => new Date(avail.endDateTime) > currentDateTime)
          .sort(
            (a, b) => new Date(a.startDateTime) - new Date(b.startDateTime)
          );

        const pastAvailability = availability.data
          .filter((avail) => new Date(avail.endDateTime) <= currentDateTime)
          .sort(
            (a, b) => new Date(b.startDateTime) - new Date(a.startDateTime)
          );

        setAvailability(futureAvailability);
        setPastAvailability(pastAvailability);
        const appointments = await doPost(
          "/api/doctor/scheduled/appointments",
          {
            id: currentUser.uid,
          }
        );

        // Filter out past appointments and sort by date
        const futureAppointments = appointments.data
          .filter((appointment) => {
            const appointmentDateTime = new Date(
              `${appointment.date} ${appointment.time}`
            );
            return appointmentDateTime > currentDateTime;
          })
          .sort(
            (a, b) =>
              new Date(`${a.date} ${a.time}`) - new Date(`${b.date} ${b.time}`)
          );

        setAppointments(futureAppointments);
      } catch (error) {
        setSnackbar({
          message: "Error fetching data.",
          show: true,
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentUser]);

  const handleBookEntireDayChange = (event) => {
    setBookEntireDay(event.target.checked);
    if (event.target.checked) {
      const selectedDate = newAvailability.startDateTime
        ? new Date(newAvailability.startDateTime).toISOString().split("T")[0]
        : new Date().toISOString().split("T")[0];
      setNewAvailability({
        startDateTime: `${selectedDate}T09:00`,
        endDateTime: `${selectedDate}T18:00`,
      });
    } else {
      setNewAvailability({ startDateTime: "", endDateTime: "" });
    }
  };

  const validateInputs = () => {
    if (!newAvailability.startDateTime || !newAvailability.endDateTime) {
      setSnackbar({
        message: "Please fill in both start and end date-time.",
        show: true,
        type: "error",
      });
      return false;
    }
    if (
      new Date(newAvailability.startDateTime) >=
      new Date(newAvailability.endDateTime)
    ) {
      setSnackbar({
        message: "End date-time must be after start date-time.",
        show: true,
        type: "error",
      });
      return false;
    }
    return true;
  };

  const handleAddAvailability = async () => {
    if (!validateInputs()) return;

    setLoading(true);
    try {
      if (editingAvailabilityId) {
        const model = {
          id: editingAvailabilityId,
          startDateTime: newAvailability.startDateTime,
          endDateTime: newAvailability.endDateTime,
        };
        await doPost("/api/doctor/availability/update", { model }).then(
          (res) => {
            if (res.succeeded) {
              setSnackbar({
                message: res.message,
                show: true,
                type: "success",
              });
              setEditingAvailabilityId(null);
            } else {
              throw new Error("Not found.");
            }
          }
        );
      } else {
        const model = {
          doctorId: currentUser.uid,
          startDateTime: newAvailability.startDateTime,
          endDateTime: newAvailability.endDateTime,
        };

        await doPost("/api/doctor/availability/create", { model }).then(
          (res) => {
            if (res.succeeded) {
              setSnackbar({
                message: res.message,
                show: true,
                type: "success",
              });
            } else {
              throw new Error("Not found.");
            }
          }
        );
      }
      setNewAvailability({ startDateTime: "", endDateTime: "" });
      setBookEntireDay(false);

      // Refresh availability list
      const availability = await doPost("/api/doctor/availability", {
        id: currentUser.uid,
      });

      // Separate into future and past availability
      const currentDateTime = new Date();
      const futureAvailability = availability.data
        .filter((avail) => new Date(avail.endDateTime) > currentDateTime)
        .sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));

      const pastAvailability = availability.data
        .filter((avail) => new Date(avail.endDateTime) <= currentDateTime)
        .sort((a, b) => new Date(b.startDateTime) - new Date(a.startDateTime));

      setAvailability(futureAvailability);
      setPastAvailability(pastAvailability);
    } catch (error) {
      setSnackbar({
        message: "Error saving availability.",
        show: true,
        type: "error",
      });
      setSnackbar({
        message: `Error saving availability. ${error.message}`,
        show: true,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEditAvailability = (avail) => {
    setNewAvailability({
      startDateTime: avail.startDateTime,
      endDateTime: avail.endDateTime,
    });
    setEditingAvailabilityId(avail.id);
  };

  const handleDeleteAvailability = async (id) => {
    setLoading(true);
    try {
      await doPost("/api/doctor/availability/delete", { id }).then((res) => {
        if (res.succeeded) {
          setSnackbar({
            message: res.message,
            show: true,
            type: "success",
          });
        } else {
          throw new Error("Not found.");
        }
      });

      // Refresh availability list
      const availability = await doPost("/api/doctor/availability", {
        id: currentUser.uid,
      });

      // Separate into future and past availability
      const currentDateTime = new Date();
      const futureAvailability = availability.data
        .filter((avail) => new Date(avail.endDateTime) > currentDateTime)
        .sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));

      const pastAvailability = availability.data
        .filter((avail) => new Date(avail.endDateTime) <= currentDateTime)
        .sort((a, b) => new Date(b.startDateTime) - new Date(a.startDateTime));

      setAvailability(futureAvailability);
      setPastAvailability(pastAvailability);
    } catch (error) {
      setSnackbar({
        message: `Error deleting availability. ${error.message}`,
        show: true,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBulkDeletePastAvailability = async () => {
    if (selectedPastAvailabilities.length === 0) {
      setSnackbar({
        message: "No past availabilities selected for deletion.",
        show: true,
        type: "error",
      });
      return;
    }

    setLoading(true);
    try {
      for (const id of selectedPastAvailabilities) {
        await doPost("/api/doctor/availability/delete", { id });
      }
      setSnackbar({
        message: "Selected past availabilities deleted successfully!",
        show: true,
        type: "success",
      });

      // Refresh availability list
      const availability = await doPost("/api/doctor/availability", {
        id: currentUser.uid,
      });

      // Separate into future and past availability
      const currentDateTime = new Date();
      const futureAvailability = availability.data
        .filter((avail) => new Date(avail.endDateTime) > currentDateTime)
        .sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));

      const pastAvailability = availability.data
        .filter((avail) => new Date(avail.endDateTime) <= currentDateTime)
        .sort((a, b) => new Date(b.startDateTime) - new Date(a.startDateTime));

      setAvailability(futureAvailability);
      setPastAvailability(pastAvailability);
      setSelectedPastAvailabilities([]);
    } catch (error) {
      setSnackbar({
        message: `Error deleting past availabilities. ${error.message}`,
        show: true,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAllPastAvailabilities = (event) => {
    if (event.target.checked) {
      const allPastAvailabilityIds = pastAvailability
        .slice(
          (pastAvailabilityPage - 1) * itemsPerPage,
          pastAvailabilityPage * itemsPerPage
        )
        .map((avail) => avail.id);
      setSelectedPastAvailabilities(allPastAvailabilityIds);
    } else {
      setSelectedPastAvailabilities([]);
    }
  };

  const handleSelectPastAvailability = (id) => {
    if (selectedPastAvailabilities.includes(id)) {
      setSelectedPastAvailabilities(
        selectedPastAvailabilities.filter((item) => item !== id)
      );
    } else {
      setSelectedPastAvailabilities([...selectedPastAvailabilities, id]);
    }
  };

  // Paginate availability
  const paginatedAvailability = availability.slice(
    (availabilityPage - 1) * itemsPerPage,
    availabilityPage * itemsPerPage
  );

  // Paginate past availability
  const paginatedPastAvailability = pastAvailability.slice(
    (pastAvailabilityPage - 1) * itemsPerPage,
    pastAvailabilityPage * itemsPerPage
  );

  // Paginate appointments
  const paginatedAppointments = appointments.slice(
    (appointmentsPage - 1) * itemsPerPage,
    appointmentsPage * itemsPerPage
  );

  return (
    <>
      <Card
        sx={{
          padding: 3,
          maxWidth: 800,
          margin: "auto",
          my: 3,
          border: {
            xs: "none",
          },
          boxShadow: {
            xs: "none",
          },
        }}
      >
        <Typography component="h1" variant="h4" gutterBottom>
          Administration
        </Typography>
        <Divider sx={{ my: 3 }} />

        <Typography variant="h5" sx={{ my: 3 }}>
          Manage Availability
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={bookEntireDay}
              onChange={handleBookEntireDayChange}
            />
          }
          label="Available Entire Day"
        />
        <TextField
          size="small"
          label="Start Date & Time"
          type="datetime-local"
          fullWidth
          margin="normal"
          value={newAvailability.startDateTime}
          onChange={(e) =>
            setNewAvailability({
              ...newAvailability,
              startDateTime: e.target.value,
            })
          }
          InputLabelProps={{ shrink: true }}
          disabled={bookEntireDay}
        />
        <TextField
          size="small"
          label="End Date & Time"
          type="datetime-local"
          fullWidth
          margin="normal"
          value={newAvailability.endDateTime}
          onChange={(e) =>
            setNewAvailability({
              ...newAvailability,
              endDateTime: e.target.value,
            })
          }
          InputLabelProps={{ shrink: true }}
          disabled={bookEntireDay}
        />
        <Button
          onClick={handleAddAvailability}
          variant="contained"
          fullWidth
          sx={{ mt: 2 }}
        >
          {editingAvailabilityId ? "Update Availability" : "Add Availability"}
        </Button>
        <Divider sx={{ my: 3 }} />
        <Typography variant="h5" sx={{ my: 3 }}>
          Upcoming Availability
        </Typography>
        <List>
          {paginatedAvailability.map((avail) => (
            <ListItem
              key={avail.id}
              sx={{ py: 0, display: "flex", justifyContent: "space-between" }}
            >
              <ListItemText
                primary={`Available from ${new Date(
                  avail.startDateTime
                ).toLocaleString()} to ${new Date(
                  avail.endDateTime
                ).toLocaleString()}`}
              />
              <div>
                <IconButton onClick={() => handleEditAvailability(avail)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteAvailability(avail.id)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </ListItem>
          ))}
        </List>
        <Pagination
          count={Math.ceil(availability.length / itemsPerPage)}
          page={availabilityPage}
          onChange={(event, value) => setAvailabilityPage(value)}
          sx={{ my: 3, display: "flex", justifyContent: "center" }}
        />
        <Divider sx={{ my: 3 }} />
        {paginatedPastAvailability.length > 0 && (
          <>
            <Typography variant="h5" sx={{ my: 3 }}>
              Past Availability
            </Typography>
            <Button
              onClick={handleBulkDeletePastAvailability}
              variant="contained"
              color="error"
              disabled={selectedPastAvailabilities.length === 0}
            >
              Delete Selected
            </Button>

            <List>
              <ListItem
                key={0}
                sx={{ p: 0, display: "flex", justifyContent: "space-between" }}
              >
                <Checkbox
                  checked={
                    selectedPastAvailabilities.length ===
                    paginatedPastAvailability.length
                  }
                  onChange={handleSelectAllPastAvailabilities}
                />
                <ListItemText primary={`Select All`} />
              </ListItem>
              {paginatedPastAvailability.map((avail) => (
                <ListItem
                  key={avail.id + 1}
                  sx={{
                    p: 0,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Checkbox
                    checked={selectedPastAvailabilities.includes(avail.id)}
                    onChange={() => handleSelectPastAvailability(avail.id)}
                  />
                  <ListItemText
                    primary={`Available from ${new Date(
                      avail.startDateTime
                    ).toLocaleString()} to ${new Date(
                      avail.endDateTime
                    ).toLocaleString()}`}
                  />
                </ListItem>
              ))}
            </List>
            <Pagination
              count={Math.ceil(pastAvailability.length / itemsPerPage)}
              page={pastAvailabilityPage}
              onChange={(event, value) => setPastAvailabilityPage(value)}
              sx={{ my: 3, display: "flex", justifyContent: "center" }}
            />
            <Divider sx={{ my: 3 }} />
          </>
        )}
        {paginatedAppointments.length > 0 && (
          <>
            <Typography variant="h5" sx={{ my: 3 }}>
              Scheduled Appointments
            </Typography>
            <List>
              {paginatedAppointments.map((appointment) => (
                <ListItem key={appointment.id} sx={{ py: 0 }}>
                  <ListItemText
                    primary={`Appointment with ${appointment.userName} on ${appointment.date} at ${appointment.time}`}
                    secondary={`Symptoms: ${appointment.symptoms.join(", ")}`}
                  />
                </ListItem>
              ))}
            </List>
            <Pagination
              count={Math.ceil(appointments.length / itemsPerPage)}
              page={appointmentsPage}
              onChange={(event, value) => setAppointmentsPage(value)}
              sx={{ my: 3, display: "flex", justifyContent: "center" }}
            />
          </>
        )}
      </Card>

      {loading && <LoadingModal open={loading} />}

      <SnackbarWebPart
        snacker={snackbar}
        onClose={() => setSnackbar({ ...snackbar, show: false })}
      />
    </>
  );
};

export default DoctorAdminConsole;

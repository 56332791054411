import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Link,
  InputLabel,
  InputAdornment,
  FormControl,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import LoadingModal from "../LoadingModal";
import SnackbarWebPart from "../webparts/snackbar.webpart";
import { doPost } from "../../services/apiService";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: "",
    show: false,
    type: "info",
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const handleMouseUpPassword = (event) => event.preventDefault();

  const navigate = useNavigate();
  const { updateActivity, setUserAndRole } = useAuth();

  const validateForm = () => {
    if (!email || !password) {
      setSnackbar({
        message: "All fields are required.",
        show: true,
        type: "error",
      });
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setSnackbar({
        message: "Invalid email address.",
        show: true,
        type: "error",
      });
      return false;
    }
    return true;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setSnackbar({ message: "", show: false, type: "info" });
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    try {
      await doPost("/api/user/login", { email, password }).then((res) => {
        if (res.succeeded) {
          setSnackbar({
            message: "Logged in successfully!",
            show: true,
            type: "success",
          });
          const user = res.data.user;
          // Manually set user and role in AuthContext
          setUserAndRole(user);

          updateActivity();

          // Redirect based on user role
          if (user.role === "patient") {
            navigate("/patient-dashboard");
          } else if (user.role === "doctor") {
            navigate("/doctor-dashboard");
          } else {
            setSnackbar({
              message: "User role not found.",
              show: true,
              type: "warning",
            });
          }
        } else {
          throw new Error(res.data.error);
        }
      });
    } catch (error) {
      setSnackbar({ message: error.message, show: true, type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography variant="h5" align="center" gutterBottom>
        Login
      </Typography>

      <form onSubmit={handleLogin}>
        <TextField
          size="small"
          label="Email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <FormControl
          sx={{ my: 1, width: "100%" }}
          variant="outlined"
          size="small"
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={
                    showPassword ? "hide the password" : "display the password"
                  }
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  onMouseUp={handleMouseUpPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ marginTop: 2 }}
        >
          Login
        </Button>
      </form>

      {/* Link to Reset Password */}
      <Typography variant="body2" align="center" sx={{ marginTop: 2 }}>
        Forgot password?{" "}
        <Link href="/reset-password" underline="hover">
          Reset Password
        </Link>
      </Typography>

      <LoadingModal open={loading} />
      <SnackbarWebPart
        snacker={snackbar}
        onClose={() => setSnackbar({ ...snackbar, show: false })}
      />
    </>
  );
};

export default Login;

import React from "react";
import { Alert, Snackbar } from "@mui/material";

export default function SnackbarWebPart({ snacker, onClose }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={snacker.show}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={snacker.type} sx={{ width: "100%" }}>
        {snacker.message}
      </Alert>
    </Snackbar>
  );
}

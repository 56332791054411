import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import healthcare from "../../assets/images/ai-healthcare-illustration.avif";

const LandingPage = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h2" gutterBottom sx={{ fontWeight: 600 }}>
            AI-Powered Healthcare Solutions
          </Typography>
          <Typography variant="body1" paragraph sx={{ mb: 3 }}>
            Revolutionizing patient care with intelligent diagnostics and
            seamless doctor-patient communication.
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              component={Link}
              to="/signup"
              variant="contained"
              size="large"
            >
              Get Started
            </Button>
            <Button
              component={Link}
              to="/products"
              variant="outlined"
              size="large"
            >
              Learn More
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              bgcolor: "secondary.main",
              height: 400,
              borderRadius: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              background: `url(${healthcare}) center center no-repeat`,
              objectFit: "cover",
            }}
          >
            <Typography>AI-Powered Healthcare</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingPage;

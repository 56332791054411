export const utilities = {
  setCookie: (cookieName, cookieValue, cookieExpiry) => {
    const now = new Date();
    now.setTime(now.getTime() + cookieExpiry * 1000);
    const next = new Date(now.getTime());
    const expires = "expires=" + next.toUTCString();
    document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
  },
  getCookie: (cookieName) => {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  sessionExpired: (viewState) => {
    const { authorised, validThrough } = viewState;
    if (authorised) {
      const now = new Date();
      const validTill = new Date(validThrough);
      return now > validTill;
    }
    return authorised;
  },
  getValidityPeriod: (minutes) => {
    const now = new Date();
    now.setTime(now.getTime() + minutes * 1000);
    const period = new Date(now.getTime());
    return period.toUTCString();
  },
  getIdentity: (gender, birthYear) => {
    const thisYear = new Date().getFullYear();
    const age = thisYear - parseInt(birthYear);
    let identity = "mwbg";
    if (age >= 12 && gender === "female") {
      identity = "woman";
    } else if (age < 12 && gender === "female") {
      identity = "girl";
    } else if (age >= 12 && gender === "male") {
      identity = "man";
    } else if (age < 12 && gender === "male") {
      identity = "boy";
    }
    return identity;
  },
};

import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./auth/AuthContext";

const ProtectedRoute = ({ children, role }) => {
  const { currentUser, userRole } = useAuth();
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // If a role is specified, check if the user has the required role
  if (role && userRole !== role) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;

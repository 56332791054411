import React from "react";
import { Typography, Container, Paper } from "@mui/material";

const AboutPage = () => {
  return (
    <Container sx={{ py: 8 }}>
      <Typography variant="h3" gutterBottom sx={{ mb: 4, textAlign: "center" }}>
        About MedicAI
      </Typography>
      <Paper elevation={0} sx={{ p: 4, mb: 4 }}>
        <Typography variant="body1" paragraph>
          MedicAI is a healthcare technology company dedicated to improving
          patient outcomes through artificial intelligence.
        </Typography>
        <Typography variant="body1" paragraph>
          Our platform connects patients with healthcare providers using
          cutting-edge AI diagnostics and streamlined communication tools.
        </Typography>
      </Paper>
    </Container>
  );
};

export default AboutPage;
